import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {
  FileBeforeUploadEvent,
  FileSelectEvent,
  FileUpload,
  FileUploadEvent
} from 'primeng/fileupload';
import { FileTypes } from 'src/app/types/misc/FileTypes';
import { MB } from 'src/app/utils/bytes/byte-conversion-factor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() showCancelButton = false;

  @Input() showUploadButton = false;

  @Input() size: 'small' | 'large' = 'small';

  @Input() subtitleTranslationKey = 'general.fileUpload.subtitle';

  @Input() titleTranslationKey = 'general.fileUpload.title';

  @Input() url!: string;

  @Input() showDragAndDropArea = true;

  @Output() choose = new EventEmitter<File[]>();

  @Output() upload = new EventEmitter<File[]>();

  @Output() uploadDone = new EventEmitter<void>();

  @Output() fileRemoved = new EventEmitter<File>();

  @ViewChild('uploader') uploader: FileUpload | undefined;

  allowedFileTypes = Object.values(FileTypes).join(',');

  environment = environment;

  files = [];

  currentFiles: File[] = [];

  FileTypes = FileTypes;

  showSpinner = false;

  uploadedFiles: File[] = [];

  maxFileSize = MB(15);

  /**
   * Handles the upload event.
   * @param {FileUploadEvent} event - The upload event.
   * @returns {void}
   */
  onUpload(event: FileUploadEvent): void {
    // Add uploaded files to the list
    this.uploadedFiles.push(...event.files);
    this.showSpinner = false;
    this.uploadDone.emit();
  }

  /**
   * Resets the uploader.
   */
  reset() {
    this.uploadedFiles = [];
    this.files = [];
    this.currentFiles = [];

    this.uploader?.clear();
  }

  /**
   * Handles the before upload event. (Fired on upload click)
   * @param {FileBeforeUploadEvent} event - The before upload event.
   * @returns {void}
   */
  uploadHandler(event: FileBeforeUploadEvent): void {
    if (event) {
      this.upload.emit(this.currentFiles);
    }
  }

  /**
   * Removes the specified file from the uploader.
   * @param {File} file - The file to remove.
   * @param {FileUpload} uploader - The uploader.
   * @returns {void}
   */
  removeFile(file: File, uploader: FileUpload): void {
    // Remove the specified file from the uploader
    const index = uploader.files.indexOf(file);

    if (index !== -1) {
      uploader._files.splice(index, 1);
    }
    this.fileRemoved.emit(file);
  }

  /**
   * Shows the spinner
   * @returns {void}
   */
  load(): void {
    this.showSpinner = true;
  }

  /**
   * Handles the select event.
   * @param {FileSelectEvent} event - The select event.
   * @returns {void}
   */
  onSelect(event: FileSelectEvent): void {
    this.currentFiles = event.currentFiles;
    this.choose.emit(event.currentFiles);
  }

  onChoose(event: MouseEvent, callback: () => void) {
    callback();
  }
}
